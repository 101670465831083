/*

Landing Zero theme - A free landing page theme from BootstrapZero
http://www.bootstrapzero.com

*/

@import url(https://fonts.googleapis.com/css?family=Questrial);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script:700);

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Questrial','Helvetica Neue',Arial,sans-serif;
    background-color: #282828;
    color: #666;
    webkit-tap-highlight-color: #222;
}

hr {
    border-width: 1px;
    clear: both;
    margin-top: 15px;
    margin-bottom: 15px;
}

hr.light {
    border-color: #FFFFFF;
}
hr.dark {
    border-color: #CCCCCC;
}

a {
    color: #792fc1;
}

a:hover,
a:focus {
    color: #DF7124;
    outline: 0;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Questrial','Helvetica Neue',Arial,sans-serif;
}

.btn-primary {
    background-color: #792fc1;
    border-color: #792fc1;
}
.btn-primary:hover {
    background-color: #460f7b;
    border-color: #460f7b;
}