.pull-center {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.text-center {
    text-align: center;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.optional label:after {
    color: #999;
    content: " (optional)";
    font-size: 12px;
    font-style: italic;
    vertical-align: super;
}
.required label:after, .required-message:before {
    color: red;
    content: " *";
    font-size: 18px;
}
.required-message {
    font-size: 14px;
}

.no-bg {
    background: none;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
    line-height: 1.5;
}

input.form-control,
select.form-control {
    height: 38px;
}

.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
}

/* larger screens */
@media (max-width: 2399px) { /* XL upper limit */
    .hidden-xl-down {
        display: none !important;
    }
}

@media (min-width: 2400px) { /* XXL */
    .hidden-xxl-up {
        display: none !important;
    }
    .hidden-xl-down {
        /* fix bug with hidden-xl-down class */
        display: block !important;
    }

    .col-xxl-1,   .col-xxl-2,   .col-xxl-3,   .col-xxl-4,    .col-xxl-5,    .col-xxl-6,
    .col-xxl-7,   .col-xxl-8,   .col-xxl-9,   .col-xxl-10,   .col-xxl-11,   .col-xxl-12,
    .col-xxxl-1,  .col-xxxl-2,  .col-xxxl-3,  .col-xxxl-4,   .col-xxxl-5,   .col-xxxl-6,
    .col-xxxl-7,  .col-xxxl-8,  .col-xxxl-9,  .col-xxxl-10,  .col-xxxl-11,  .col-xxxl-12,
    .col-xxxl-13, .col-xxxl-14, .col-xxxl-15, .col-xxxl-16,  .col-xxxl-17,  .col-xxxl-18,
    .col-xxxl-19, .col-xxxl-20, .col-xxxl-21, .col-xxxl-22,  .col-xxxl-23,  .col-xxxl-24 {
        float: left;
        min-height: 1px;
        padding-left: .9375rem;
        padding-right: .9375rem;
        position: relative;
    }
    .col-xxl-1 {
        width: 8.333333%;
    }
    .col-xxl-2 {
        width: 16.666667%;
    }
    .col-xxl-3 {
        width: 25%;
    }
    .col-xxl-4 {
        width: 33.333333%;
    }
    .col-xxl-5 {
        width: 41.666667%;
    }
    .col-xxl-6 {
        width: 50%;
    }
    .col-xxl-7 {
        width: 58.333333%;
    }
    .col-xxl-8 {
        width: 66.666667%;
    }
    .col-xxl-9 {
        width: 75%;
    }
    .col-xxl-10 {
        width: 83.333333%;
    }
    .col-xxl-11 {
        width: 91.666667%;
    }
    .col-xxl-12 {
        width: 100%;
    }
}

@media (max-width: 4499px) { /* XXL upper limit */
    .hidden-xxl-down {
        display: none !important;
    }
}

@media (min-width: 4500px) { /* XXXL */
    .hidden-xxxl-up {
        display: none !important;
    }

    .col-xxxl-1 {
        width: 4.166667%;
    }
    .col-xxxl-2 {
        width: 8.333333%;
    }
    .col-xxxl-3 {
        width: 12.5%;
    }
    .col-xxxl-4 {
        width: 16.666667%;
    }
    .col-xxxl-5 {
        width: 20.833333%;
    }
    .col-xxxl-6 {
        width: 25%;
    }
    .col-xxxl-7 {
        width: 29.166667%;
    }
    .col-xxxl-8 {
        width: 33.333333%;
    }
    .col-xxxl-9 {
        width: 37.5%;
    }
    .col-xxxl-10 {
        width: 41.666667%;
    }
    .col-xxxl-11 {
        width: 45.833333%;
    }
    .col-xxxl-12 {
        width: 50%;
    }
    .col-xxxl-13 {
        width: 54.166667%;
    }
    .col-xxxl-14 {
        width: 58.333333%;
    }
    .col-xxxl-15 {
        width: 62.5%;
    }
    .col-xxxl-16 {
        width: 66.666667%;
    }
    .col-xxxl-17 {
        width: 70.833333%;
    }
    .col-xxxl-18 {
        width: 75%;
    }
    .col-xxxl-19 {
        width: 79.166667%;
    }
    .col-xxxl-20 {
        width: 83.333333%;
    }
    .col-xxxl-21 {
        width: 87.5%;
    }
    .col-xxxl-22 {
        width: 91.666667%;
    }
    .col-xxxl-23 {
        width: 95.833333%;
    }
    .col-xxxl-24 {
        width: 100%;
    }
}

.btn-padding {
    padding: 0.375rem 0.75rem;
}