#cookies {
	padding: 0 20px;
}
#cookies h2, #cookies h3 {
	border-bottom: 1px solid #CCC;
	margin: 20px 0;
}
#cookies p {
	margin: 10px 0;
}
#cookies a {
	color: #2FC2EF;
}
#cookies table {
	border-collapse: collapse;
}
#cookies th {
	font-size: 18px;
	text-align: left;
}
#cookies th, #cookies td {
	padding: 5px 40px 5px 10px;
}
#cookies td {
	border-top: 1px solid #CCC;
}
#cookies td:first-child {
	border-right: 1px solid #CCC;
}
#cookies .setting {
	background: #EEE;
	color: #6B5;
	font-size: 18px;
	font-weight: bold;
	padding: 5px 10px;
	text-align: right;
}
#cookies .btn {
	border: none;
	border-radius: 5px;
	padding: 10px 15px;
}
#cookies .btn-primary {
    background: #56F;
    color: #FFF;
}
#cookies .pull-center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}