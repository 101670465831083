@media (min-width: 992px) {
    html, body {
        overflow: hidden;
    }
    .body {
        height: calc(100vh - 140px);
        overflow: auto;
        padding-bottom: 0;
    }
    .sidebar-container {
        padding: 18px;
    }
    .sidebar-link {
        font-size: 22px;
    }
}
@media (max-width: 1199px) {
}

/*@media (min-width: 1200px) {
    .sidebar-link {
        font-size: 30px;
	}
	.sidebar input {
	    font-size: 24px;
	    width: 142px;
	}
	.sidebar .fa-search {
	    font-size: 30px;
	}
    .container {
        margin-left: 240px;
    }
}
*/