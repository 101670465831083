.articles .odd {
    background: #EEEEEE;
}
.article-link {
    color: #666666;
    display: block;
    padding: 5px 10px;
}
.article-link span {
    display: block;
}
.article-link .title {
    font-size: 24px;
    font-weight: bold;
}
.article-link .date {

}

.articles h1 {
    font-size: 32px;
}

.articles h2 {
    font-size: 24px;
    margin-top: 10px;
}

.articles p {
    margin-bottom: 20px;
}