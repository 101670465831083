/* TODO: orientation: portrait and orientation: landscape to set font-size 4.3vh/4.8vw */
html, body {
    background: #F7F7F7;
    height: 100%;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
.h1, .h2 {
    font-family: "Passion One", Arial, sans-serif;
}
.h3, .h4 {
    color: #333333;
    font-weight: bold;
}
p {
    margin: 0;
}
.js .nojs {
    display:none;
}
.header .nojs {
    bottom: 0;
    margin: 10px;
    padding: 5px 8px;
    position: absolute;
    right: 0;
    z-index: 1;
}
.nav-bar {
    height: 30px;
    left: 0;
    position: absolute;
    top: 70px;
    z-index: 1;
}
.dropdown-item .fa {
    margin-right: 5px;
    text-align: center;
    width: 20px;
}
.sidebar {
    height: 100%;
    height: 100vh;
}
.sidebar-container {
    padding: 30px;
}
.sidebar-link, .sidebar-link:active, .sidebar-link:focus {
    float: right;
    font-weight: bold;
    clear: both;
    text-decoration: none;
}
.header {
    background: url(images/header.jpg) no-repeat;
    height: 100px;
    position: relative;
    -webkit-transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    transition: height 0.4s ease;
}
.header-extension {
    background: url(images/header-extension.jpg) repeat-x;
    height: 100%;
    left: 1920px;
    position: absolute;
    right: 0;
    z-index: 0;
}
.sm-header-menu {
    bottom: 0;
    left: 5px;
    font-size: 17px;
    position: absolute;
    z-index: 999;
}
#header-home-link {
    display: block;
    height: 45px;
    left: 92px;
    position: absolute;
    top: 20px;
    width: 235px;
    z-index: 1;
}
#hdr-icons {
    height: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 116px;
}
.hdr-icon {
    display: block;
    float: right;
    font-size: 24px;
    height: 23px;
    margin: 0 4px;
    opacity: 1;
}
.container {
    height: 100%;
    overflow: auto;
    padding: 0;
    width: auto;
}
.notification-bar {
    line-height: 1em;
    margin: -20px -20px 20px;
    padding: 10px 20px;
}
.body {
    margin: 0 -15px;
    min-height: calc(100vh - 140px);
    padding: 20px 20px 0 20px;
}
.clear {
    clear: both;
}
/* normalize browsers */

/* home page */
.panel-borderless, .panel-borderless .panel-heading, .panel-borderless .list-group-item {
    box-shadow: none;
    background: none;
    border: none;
}
.panel-compact .panel-heading, .panel-compact .list-group-item, .panel-compact .panel-body {
    padding: 4px 6px;
}

/* links */
a:hover {
    text-decoration: none;
}

/* pagination */
.pagination .ellipsis {
    background: none;
    border: none;
}

/* alignment */
body .center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* font size */
.text-xs {
    font-size: 8px;
}
.text-sm {
    font-size: 10px;
}
.text-md {
    font-size: 12px;
}
.text-lg {
    font-size: 14px;
}
.text-2x {
    font-size: 18px;
}
.text-3x {
    font-size: 22px;
}
.text-4x {
    font-size: 28px;
}
.text-5x {
    font-size: 32px;
}

/* font weight */

/* form controls */

/* buttons */

/* columns */
.center {
    float: none;
    margin-left: auto;
    margin-right: auto;
}
/* certifications */
.crt-sm {
    background: url(images/crts-icons-sm.png) no-repeat 0 -96px;
    display: inline-block;
    height: 16px;
    width: 20px;
}
.crt-sm-u {
    background-position: 0 0;
}
.crt-sm-pg {
    background-position: 0 -16px;
}
.crt-sm-12a {
    background-position: 0 -33px;
}
.crt-sm-15 {
    background-position: 0 -48px;
}
.crt-sm-16 {
    background-position: 0 -64px;
}
.crt-sm-18 {
    background-position: 0 -80px;
}
.crt-md {
    background: url(images/crts-icons-md.png) no-repeat 0 -145px;
    display: inline-block;
    height: 24px;
    width: 30px;
}
.crt-md-u {
    background-position: 0 0;
}
.crt-md-pg {
    background-position: 0 -25px;
}
.crt-md-12a {
    background-position: 0 -49px;
}
.crt-md-15 {
    background-position: 0 -73px;
}
.crt-md-16 {
    background-position: 0 -97px;
}
.crt-md-18 {
    background-position: 0 -121px;
}
.crt-lg {
    background: url(images/crts-icons-lg.png) no-repeat 0 -240px;
    display: inline-block;
    height: 40px;
    width: 50px;
}
.crt-lg-u {
    background-position: 0 0;
}
.crt-lg-pg {
    background-position: 0 -41px;
}
.crt-lg-12a {
    background-position: 0 -81px;
}
.crt-lg-15 {
    background-position: 0 -121px;
}
.crt-lg-16 {
    background-position: 0 -161px;
}
.crt-lg-18 {
    background-position: 0 -201px;
}

/* Cookies popup */
#cookies-popup {
    background: #000;
    background: RGBA(0,0,0,0.8);
    border-radius: 10px;
    bottom: 10px;
    color: #FFF;
    left: 10px;
    padding: 20px;
    position: absolute;
    position: fixed;
    right: 10px;
    z-index: 999999999;
}
#cookies-popup p {
    font-size: 16px;
    margin: -2px 0 0 0;
}
#cookies-popup b {
    display: block;
    float: left;
    font-size: 100px;
    height: 48px;
    line-height: 90px;
    margin: 0 20px 0 0;
    width: 40px;
}
#cookies-pop-buttons {
    float: right;
    margin: -5px 0 0 20px;
    width: 30px;
}
#cookies-popup a {
    color: #FFF;
    display: block;
    font-size: 24px;
    margin-bottom: 10px;
}
#cookies-popup a:hover {
}

.footer {
    bottom: 0;
    height: 40px;
    left: 0;
    padding: 8px 20px;
    position: fixed;
    text-align: right;
    width: calc(100vw);
}
.footer a {
    color: inherit;
    margin: 0 6px;
}
