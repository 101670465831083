@media (min-width: 768px) {
    .sidebar-link {
        font-size: 24px;
    }
    .body {
        padding-bottom: 40px;
    }
}

@media (max-width: 991px) {
    .sidebar, .nav-bar {
        padding: 0;
    }
    .sidebar {
        background: none;
        height: 28px;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 10;
    }
    .sidebar-container {
        padding: 0;
    }
    .sidebar-link, .sidebar-link.active {
        display: inline-block;
        clear: none;
        float: none !important;
        font-size: 14px;
        margin: 4px 10px;
    }
    .sidebar form {
        display: block;
        float: right;
        position: absolute;
        right: 20px;
        top: 1px;
        width: 150px;
    }
    .sidebar form .input-group-btn {
        left: 10px;
    }
    .sidebar input {
        font-size: 16px;
        height: 24px;
        width: 94px;
    }
    .sidebar .fa-search {
        font-size: 18px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sidebar, .nav-bar {
       padding: 0;
    }
    .sidebar {
        top: 72px;
    }
    .sidebar form {
        top: -5px;
    }
}