@media (min-width: 1200px) {
    .sidebar-container {
        padding: 30px;
    }  
    .sidebar-link {
        font-size: 30px;
    }
}

/*@media (min-width: 1200px) {
    .sidebar-link {
        font-size: 30px;
	}
	.sidebar input {
	    font-size: 24px;
	    width: 142px;
	}
	.sidebar .fa-search {
	    font-size: 30px;
	}
    .container {
        margin-left: 240px;
    }
}
*/