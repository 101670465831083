.captcha {
    background: #EEEEEE;
    border: 1px solid #CCCCCC;
    min-height: 72px;
    padding: 1rem;
    width: 100%;
}
.captcha h2 {
    font-size: 18px;
}
.captcha h3 {
    font-size: 16px;
}
.captcha input {
    display: inline;
    height: 20px;
    margin: 6px 5px;
    width: 20px;
}

.captcha.has-danger {
    color: #A94442;
    background-color: #F2DEDE;
    border-color: #EBCCCC;
}