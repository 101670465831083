div.cloud {
    width: 900px;
    text-align: center;
    margin: 20px auto
}

div.cloud a.scloud {
    font-weight: bold;
    margin: 5px;
    white-space: nowrap;
    opacity: 0.5;
    filter: alpha(opacity = 50);
    position: relative;
    line-height: .825em
}

div.cloud a.scloud:hover {
    opacity: 1
}

div.cloud a.size7 {
    font-size: 0.65em;
    z-index: 7
}

div.cloud a.size6 {
    font-size: 0.75em;
    z-index: 6
}

div.cloud a.size5 {
    font-size: 0.825em;
    z-index: 5
}

div.cloud a.size4 {
    font-size: 1em;
    z-index: 4
}

div.cloud a.size3 {
    font-size: 2.125em;
    z-index: 3
}

div.cloud a.size2 {
    font-size: 3.25em;
    z-index: 2
}

div.cloud a.size1 {
    font-size: 4em;
    line-height: 1em;
    z-index: 1
}