/* colors fg and bg */
.text-orange-50      { color: #f8e7dc; }
.text-orange-100     { color: #f5d0b6; }
.text-orange-200     { color: #f4b78c; }
.text-orange-300     { color: #ed995f; }
.text-orange-400     { color: #e8843e; }
.text-orange-500, .text-hover-orange-500:hover, .text-hover-orange-500:focus { color: #DF7124; }
.text-orange-600     { color: #dc6a19; }
.text-orange-700     { color: #cf6113; }
.text-orange-800     { color: #b1510c; }
.text-orange-900     { color: #803703; }
.text-blue-500  { color: #2491DF; }
.text-grey-500  { color: #666;    }
.text-white-500 { color: #FBFBFB; }
.text-black-500 { color: #333;    }

.bg-grey-100 { background: #1C1C1C }
.bg-grey-200 { background: #383838 }
.bg-grey-300 { background: #545454 }
.bg-grey-400 { background: #707070 }
.bg-grey-500 { background: #8C8C8C }
.bg-grey-600 { background: #A8A8A8 }
.bg-grey-700 { background: #C4C4C4 }
.bg-grey-800 { background: #E0E0E0 }
.bg-grey-900 { background: #FCFCFC }
.bg-orange-500, .bg-hover-orange-500:hover, .bg-hover-orange-500:focus { background: #DF7124; }
.bg-blue-500  { background: #2491DF; }
.sidebar .active { color: #DF7124; }
/*
.text|bg-primary   
.text|bg-warning   yellow
.text|bg-danger    red
.text|bg-success   green
.text|bg-info      blue
*/