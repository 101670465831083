.search .nav .label {
    font-size: 10px;
    position: relative;
    top: -10px;
}

.search-result .subtitle {
    display: block;
    font-size: 16px;
    text-indent: 10px;
}